import React from "react";
import { FaClock, FaRocket } from "react-icons/fa";
import Lottie from "lottie-react";
import AOS from "aos";
import "aos/dist/aos.css";
import Countdown from "react-countdown";
import animationData from "./assets/coming-soon.json"; // fichier Lottie d'animation "Coming Soon"

AOS.init(); // Initialiser AOS pour les animations

function App() {
  // Date cible pour le compte à rebours (par exemple, le 1er janvier 2025)
  const launchDate = new Date("2025-04-01T00:00:00");

  return (
    <div className="min-h-screen h-screen bg-gradient-to-r from-gray-700 to-gray-900 flex flex-col justify-center items-center text-white p-6">
      <header
        className="text-center"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <h1 className="text-4xl font-bold mb-3">Bienvenue sur MathPro</h1>
        <h2 className="text-xl">
          Votre guide complet pour réussir en mathématiques au Lycée
        </h2>
      </header>

      <section
        className="mt-8 mb-6 text-center"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <h3 className="text-3xl font-semibold mb-3">À propos de MathPro</h3>
        <p className="text-base max-w-xl mx-auto">
          MathPro est une plateforme dédiée aux élèves marocains, offrant des
          ressources mathématiques de qualité pour les niveaux Tronc Commun, 1er
          Bac, et surtout pour les étudiants de 2ème Bac. Accédez à des cours,
          des exercices, et des préparations aux Olympiades pour exceller dans
          vos études.
        </p>
      </section>

      <div className="mt-6 mb-6" data-aos="zoom-in" data-aos-duration="1000">
        <Lottie
          animationData={animationData}
          loop={true}
          style={{ width: 200, height: 200 }}
        />
      </div>

      <p className="text-base mb-6" data-aos="fade-up" data-aos-duration="1000">
        Le projet est en cours de préparation. Restez à l'écoute pour plus de
        détails !
      </p>

      <div
        className="text-2xl mb-6"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <Countdown
          date={launchDate}
          renderer={(props) => (
            <div>
              {props.days}j {props.hours}h {props.minutes}m {props.seconds}s
              restantes
            </div>
          )}
        />
      </div>

      <div
        className="flex space-x-6"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="flex items-center space-x-2">
          <FaClock className="text-3xl" />
          <span className="text-lg">Disponible bientôt</span>
        </div>
        <div className="flex items-center space-x-2">
          <FaRocket className="text-3xl" />
          <span className="text-lg">Restez connectés</span>
        </div>
      </div>
    </div>
  );
}

export default App;
